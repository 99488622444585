<script setup lang="ts">
const bannerImg = ref<string[]>(['autoBanner'])
const { allCompleted } = useImageLoaderTracker(bannerImg)
</script>

<template>
  <div>
    <PvSkeleton v-show="!allCompleted" class="aspect-[5665/2452] w-full" />
    <div v-show="allCompleted">
      <div class="sm:p-3 lg:p-5" @click="navigateTo('/store/auto')">
        <template v-if="$viewport.match('sm')">
          <img id="autoBanner" src="/img/banners/banner_auto_movile.png" class="w-full rounded" />
        </template>
        <template v-else>
          <img id="autoBanner" src="/img/banners/banner_auto.png" class="w-full rounded" />
        </template>
      </div>
    </div>
  </div>
</template>
